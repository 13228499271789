import React from "react";
import Layout from "@/layout";
import "./index.less"

export default function Index() {
    const clearLocalStorage = () => {
        window.localStorage.clear()
    }
    return (<Layout needLogin={false} needWechat={false}>
        <div className="setting-index">
            <div className="setting-index-card">
                <div className="setting-index-card-content" onClick={() => clearLocalStorage()}>
                    点击此处清理当前页面所有缓存
                </div>
            </div>
        </div>
    </Layout>)
}

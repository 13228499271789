import React from "react"
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom"
import Index from "@/pages/index"
import Setting from "@/pages/setting"

import WechatOfficial from '@/pages/wechat/official'
import WechatWork from '@/pages/wechat/work'
import WechatJoin from '@/pages/wechat/join'

import Help from '@/pages/help'
import HelpDetail from '@/pages/help/detail'

import Notice from '@/pages/notice'
import RedirectPage from '@/pages/redirect'


export default function BaseRoute() {
    return (<BrowserRouter>
        <Switch>
            <Route exact path="/" component={Index}/>
            <Route exact path="/setting" component={Setting}/>
            {/* 微信关注 */}
            <Route exact path='/wechat/official' component={WechatOfficial}/>
            <Route exact path='/wechat/work' component={WechatWork}/>
            <Route exact path='/wechat/join' component={WechatJoin}/>
            {/* 帮助中心 */}
            <Route exact path='/help' component={Help}/>
            <Route exact path='/help/:id?' component={HelpDetail}/>

            {/* 通知跳转页面 */}
            <Route exact path='/notice' component={Notice}/>

            <Route exact path='/redirect' component={RedirectPage}/>

            {/* 拦截路由 */}
            <Route path="*" exact render={() => (<Redirect to="/"/>)}/>

        </Switch>
    </BrowserRouter>)
}


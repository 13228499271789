import React, {useCallback, useEffect} from "react";
import Layout from "@/layout";
import {ApiTransferCode} from "@/api/system";
import "./index.less"

export default function Notice() {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('t');

    const fetchData = useCallback(async (code) => {
        const data = await ApiTransferCode({code: code})
        if (data) {
            window.location.href = "weixin://dl/business/?t=" + data
        }
    }, [])

    useEffect(() => {
        if (code) {
            fetchData(code)
        }
    }, [fetchData, code])

    return (<Layout needLogin={false} needWechat={false}>
        <div className="home-index">
            <div className="home-index-card">
                <div className="home-index-card-content">
                    跳转中...
                </div>
            </div>
        </div>
    </Layout>)
}

import fetch from "@/plugin/axios"

export const ApiLogin = (params) => (fetch({
    url: "v1/user/login", method: "POST", params,
}))

export const ApiDirtyLogin = (params) => (fetch({
    url: "v1/user/forceLogin", method: "GET", params
}))

export const ApiInfo = () => (fetch({
    url: "v1/user/info",
}))

export const ApiSaveUserInfo = (params) => (fetch({
    url: "v1/user/saveUserInfo", method: "POST", params
}))

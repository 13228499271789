import React from "react";
import Layout from "@/layout";
import "./index.less"

export default function Index() {
    return (<Layout needLogin={false} needWechat={false}>
        <div className="home-index">
            <div className="home-index-card">
                <div className="home-index-card-content">
                    未知页面
                </div>
            </div>
        </div>
    </Layout>)
}

import fetch from "@/plugin/axios"

export const ApiInit = () => (
    fetch({
        url: "v1/info/init"
    })
)

export const ApiPositions = (params) => (
    fetch({
        url: `v1/info/positions?parent_id=${params}`,
    })
)

export const ApiTransferCode = (params) => (
    fetch({
        url: `v1/info/transferCode`,
        params
    })
)

import * as VAL from "@/store/constants/user"
import {ApiInfo} from "@/api/user";

export const setTokenInfo = (data) => {
    return {type: VAL.INFOTOKEN, data: data}
}

export const setUserInfo = (data) => {
    return {type: VAL.INFOUSER, data: data}
}

export const setIsLogin = (data) => {
    return {type: VAL.ISLOGIN, data: data}
}

export const setLogout = () => {
    return {type: VAL.LOGOUT, data: {}}
}

export const getUserInfo = () => {
    return dispatch => {
        (async () => {
            const data = await ApiInfo()
            if (data) {
                dispatch(setUserInfo(data))
            }
        })()
    }
}

import React, {useEffect} from "react";
import {Provider} from "react-redux";
import Route from "@/router/index"
import {getInitData} from "@/store/actions/app"
import store from "@/store/instance"
import wx from "weixin-js-sdk"
import "./App.css";

window.$wx = wx

function App() {
    useEffect(() => {
        store.dispatch(getInitData())
    }, [])

    return (<Provider store={store}>
        <Route/>
    </Provider>);
}

export default App;

import React, {useCallback, useEffect, useState} from 'react'
import {Toast} from "antd-mobile"
import Layout from "@/layout"
import {ApiWorkCode} from "@/api/wechat"
import ImageHeader from "../assets/header-join.png"
import ImageQrcodeTips from "../assets/qrcode-work.png"
import '../index.less'

const Page = () => {
    const [qrcode, setQrcode] = useState("")

    const fetchQrcode = useCallback(async () => {
        const data = await ApiWorkCode({
            state: 'app添加',
            page: "/pages/home/index",
            share: {
                media_id: "22FaPaOc4K_9Rxux4I_SAfcaotaXxkdu3eMevZ09_1mIoNuIk4B-9zN3a8DNhOnYp",
                title: '欢迎来到高质量政企白领交友社区'
            }
        })
        setQrcode(data)
    }, [])

    const getWechatObject = useCallback(() => {
        fetchQrcode()
        Toast.show({
            icon: "loading", content: "加载中…", duration: 0,
        })
        window.$wx.ready(() => Toast.clear())
    }, [fetchQrcode])

    useEffect(() => {
        getWechatObject()
    }, [getWechatObject])

    const previewQrcode = () => {
        window.$wx.previewImage({
            current: qrcode, urls: [qrcode]
        })
    }

    return (<Layout bgcolor=" linear-gradient(180deg, #7394FF 0%, #3A46B6 100%)">
        <div className="wechat-subscribe">
            <div className="wechat-subscribe-header">
                <img src={ImageHeader} alt=""/>
            </div>
            <div className="wechat-subscribe-box">
                <div className="wechat-subscribe-box-title">
                    添加官方客服详细洽谈
                </div>
                <div className="wechat-subscribe-box-image" onClick={() => previewQrcode()}>
                    <img src={qrcode} alt=""/>
                </div>
                <div className="wechat-subscribe-box-tips">
                    <img src={ImageQrcodeTips} alt=""/>
                </div>
            </div>
        </div>
    </Layout>)
}

export default Page

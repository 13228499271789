import fetch from "@/plugin/axios"

export const ApiGetQrcode = (params) => (fetch({
    url: "v1/wechat/getQrcode", method: 'POST', params
}))

export const ApiGetLink = (params) => (fetch({
    url: "v1/wechat/getLink", method: 'POST', params
}))

export const ApiJsConfig = (params) => (fetch({
    url: "v1/wechat/getJsConfig", params
}))

export const ApiWorkCode = (params) => (fetch({
    url: "v1/wechat/getWorkCode", method: 'POST', params
}))

import React, {useCallback, useEffect} from "react";
import Layout from "@/layout";
import {ApiGetLink} from "../../api/wechat";
import "./index.less"

export default function Notice() {
    const fetchData = useCallback(async () => {
        const data = await ApiGetLink({page: "/pages/manual/category/index"})
        if (data) {
            window.location.href = data
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (<Layout needLogin={false} needWechat={true}>
        <div className="home-index">
            <div className="home-index-card">
                <div className="home-index-card-content">
                    跳转中...
                </div>
            </div>
        </div>
    </Layout>)
}
